._ .attachments-submit .policy {
  padding: 5px 0;
}
._ .attachments-submit .policy > div {
  display: flex;
}
._ .attachments-submit .policy > div .ch-div {
  padding: 0 6px 0 0 !important;
}
._ #Attachment_Agreed {
  margin-right: 4px !important;
}
body.all-scroll {
  cursor: all-scroll !important;
}
body.all-scroll * {
  cursor: all-scroll !important;
}
.svg-old-browser {
  background: url("img/browser-not-supported.png") center center no-repeat;
  bottom: 10px;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.svg-editor-popup .main-svg {
  border: none;
  width: 700px;
  height: 540px;
  display: block;
  overflow: hidden;
}
.main-svg {
  border: 1px solid #ccc;
  width: 821px;
  height: 526px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: #fff;
}
.main-svg.udu__main-image-svg {
  border: 1px solid transparent !important;
}
svg {
  max-width: 801px;
  max-height: 506px;
}
.svg-editor-popup svg {
  max-width: 680px;
  max-height: 520px;
}
.preview-images ul li {
  position: relative;
  margin: 5px 0 !important;
  padding: 0 !important;
}
.preview-images ul {
  list-style: none;
  margin: 0 !important;
  padding: 0;
}
.preview-images {
  padding: 0 5px !important;
  border: 1px solid #ccc;
  vertical-align: top;
  height: 526px;
  overflow: auto;
  width: 155px;
  text-align: center;
  float: right;
  margin-right: 4px !important;
}
.preview-images img {
  max-height: 150px;
  max-width: 150px;
  cursor: pointer;
}
.main-svg-wrapper.svg-read-only .operations {
  display: none !important;
}
.main-svg-wrapper.svg-no-text-editor .operations button.svg-editor-add-text {
  display: none !important;
}
.main-svg-wrapper.svg-no-image-editor .operations button.svg-editor-add-image,
.main-svg-wrapper.svg-no-image-editor .operations button.svg-editor-delete-image {
  display: none !important;
}
.operations button.svg-editor-zoom-out {
  background-position: 3px 3px !important;
}
.operations button.svg-editor-zoom-in {
  background-position: 3px -30px !important;
}
.operations button.svg-editor-add-image {
  background-position: 3px -64px !important;
}
.operations button.svg-editor-delete-image {
  background-position: 3px -101px !important;
}
.operations button.svg-editor-bring-back {
  background-position: 3px -139px !important;
}
.operations button.svg-editor-bring-front {
  background-position: 3px -177px !important;
}
.operations button.svg-editor-clear-all {
  background-position: 3px -215px !important;
}
.operations button.svg-editor-save {
  background-position: 3px -252px !important;
}
.operations button.svg-editor-undo {
  background-position: 3px -295px !important;
}
.operations button.svg-editor-redo {
  background-position: 3px -334px !important;
}
.operations button.svg-editor-add-text {
  background-position: 3px -371px !important;
}
.operations button.svg-editor-color {
  background-position: 3px -418px !important;
}
.operations button.svg-editor-fonts {
  background-position: 3px -460px !important;
}
.operations button.svg-editor-bold {
  background-position: 4px -502px !important;
}
.operations button.svg-editor-italic {
  background-position: 3px -540px !important;
}
.operations button.svg-editor-underline {
  background-position: 4px -582px !important;
}
.operations button:active:not(:disabled) {
  border: 1px solid #aaa !important;
  box-shadow: 0 0 5px #888 inset !important;
}
.operations button:hover:not(:disabled) {
  box-shadow: 0 0 5px #aaa inset !important;
  background-color: #eee !important;
}
.operations button:disabled {
  opacity: 0.5;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome & Safari 6+ */
  cursor: default;
}
.operations.text-line button {
  display: inline-block;
  float: left;
  margin-left: 1px !important;
}
.operations button {
  display: block !important;
  padding: 0 !important;
  width: 40px !important;
  height: 40px !important;
  margin: 5px;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 5px #ccc inset !important;
  background: #f0f0f0 url("img/sprite.svg.editor.png") !important;
  background-repeat: no-repeat !important;
}
li:hover .operations,
li.intact .operations {
  right: 10px;
}
.loading .main-svg {
  background: url("../../img/preloader.gif") center center no-repeat;
}
.loading .operations {
  display: none !important;
}
.operations.text-line.active {
  bottom: 10px;
}
.operations.text-line {
  left: 10px !important;
  right: 60px !important;
  top: inherit;
  bottom: -60px;
}
.operations {
  position: absolute;
  right: -60px;
  top: 10px;
  transition: all 0.6s ease-in-out 0s;
}
fieldset > ul.svg-editor {
  display: flex;
}
.svg-editor {
  margin: 0 !important;
  padding: 0 !important;
}
.svg-editor > li {
  display: inline-block;
}
.main-svg-wrapper {
  box-shadow: 2px 2px 2px #ccc;
  position: relative;
  overflow: hidden;
  padding: 0 !important;
}
#div-block .block-wait {
  display: none;
  height: 200px;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  position: fixed;
  top: 50%;
  width: 200px;
}
#div-block {
  background: #000;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}
._ .svg-editor .attachment-mark.loading {
  left: 0;
}
._ .svg-editor .attachment-data iframe {
  width: 130px;
}
._ .svg-editor .attachment-mark.loading {
  width: 134px;
}
._ .svg-editor li:hover > .attachment-link {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #000;
  box-shadow: 0 0 4px #666;
}
._ .svg-editor .attachment-link {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 11px;
  right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 4px;
  border: 1px solid #eee;
  background-color: rgba(255, 255, 255, 0.6);
  color: #222;
}
._ .svg-editor li:hover > .attachment-data {
  border: 1px solid #444 !important;
  box-shadow: 0 0 4px #666;
}
._ .svg-editor .attachment-data {
  background-color: #eee !important;
  border: 1px solid #fff !important;
  box-shadow: 0 0 4px #ccc;
  width: 130px;
  transition: all 0.9s ease-in-out 0s;
}
/*color picker*/
.svg-editor-color-picker {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 5px #000;
  display: none;
  height: 184px;
  left: 50%;
  margin-left: -97px;
  margin-top: -180px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  width: 350px;
  z-index: 60000;
  padding: 10px;
}
.svg-editor-color-picker .close:hover {
  background-position: 0 -37px;
}
.svg-editor-color-picker .close {
  background: url("img/sprite.embeddable.image.png") 0 0 no-repeat;
  border: none;
  cursor: pointer;
  height: 36px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 36px;
}
svg text,
svg image {
  cursor: pointer;
}
/*svg popups*/
.udu-svg-edit-popup .block label {
  width: 100px;
  border-bottom: 1px dotted #ccc;
  display: inline-block;
}
.udu-svg-edit-popup .block > div.buttons {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  text-align: center;
  border-top: 1px solid #ccc;
}
.udu-svg-edit-popup .block > div {
  padding: 6px;
}
.udu-svg-edit-popup select {
  border: 1px solid #ccc;
  padding: 3px;
  border-radius: 2px;
  max-width: 180px;
}
.udu-svg-edit-popup {
  font-size: 12px;
  font-family: Arial, Helvetica, Sans-Serif;
}
#udu-svg-font-selector {
  height: 150px;
  padding-top: 50px;
}
h4.udu__h4-required {
  display: inline-block;
  background: url("https://cdn.uducat.com/static/esb/misc/img/asterisk.png") 99% center no-repeat;
  padding-right: 15px;
}
/*window wrapper*/
.udu-svg__window-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.01);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color 0.3s;
  z-index: 9000;
}
.udu-svg__window-wrapper.udu__visible {
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: auto;
}
.udu-svg__window-wrapper.udu__visible .udu-svg-editor-win {
  opacity: 1;
}
.udu-svg-editor-win {
  background-color: #fff !important;
  border-radius: 6px;
  box-shadow: 0 0 5px #000;
  height: 600px;
  opacity: 0.01;
  overflow: hidden;
  padding-top: 30px;
  position: relative;
  transition: all 0.4s;
  width: 700px;
  z-index: 9100;
}
.udu-svg-editor-win.mode-F {
  max-height: 95%;
  max-width: 90%;
  width: 95%;
  height: calc(95% - 50px);
}
.udu-svg-editor-win.mode-F .svg-editor > li {
  bottom: 72px;
  display: flex;
  left: 0;
  position: absolute;
  right: 15px;
  top: 44px;
}
.udu-svg-editor-win.mode-F .svg-editor > li .main-svg,
.udu-svg-editor-win.mode-F .svg-editor > li .svg-fields {
  height: 100% !important;
  box-sizing: border-box;
}
.udu-svg-editor-win .bottom-buttons button.delete-image:hover {
  background-color: #d61b12;
}
.udu-svg-editor-win .bottom-buttons button.delete-image {
  background-color: #e62117;
  color: #fff;
}
.udu-svg-editor-win .bottom-buttons button:hover {
  background-color: #e6e6e6;
}
.udu-svg-editor-win .bottom-buttons button {
  background: #f2f2f2 url(https://cdn.uducat.com/static/esb/udu-css/img/btn/bg-win-btn.png) 0 100% repeat-x;
  border: 1px solid #ddd;
  cursor: pointer;
  height: 30px;
  margin: 0 3px;
  padding: 5px 25px;
  border-radius: 4px;
}
.udu-svg-editor-win .bottom-buttons {
  background-color: #eee;
  border-top: 1px solid #ccc;
  position: absolute;
  left: 0;
  right: 0;
  text-align: right;
  bottom: 0;
  padding: 16px;
}
.udu-svg-editor-win .main-svg-wrapper {
  box-shadow: none;
}
.udu-svg-editor-win .loading {
  background-image: url("img/preloader.gif");
  background-position: center center;
  background-repeat: no-repeat;
}
.udu-svg-editor-win .close {
  background: url("img/sprite.embeddable.image.png") 0 0 no-repeat !important;
  border: none;
  cursor: pointer;
  height: 36px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 36px;
}
.udu-svg-editor-win .svg-fields .title.required span {
  background: url("https://cdn.uducat.com/static/esb/misc/img/asterisk.png") 99% center no-repeat;
}
.udu-svg-editor-win .svg-fields .title span {
  padding: 4px 15px 4px 4px;
  color: #444;
  font-weight: bold;
  display: inline-block;
  font-size: 13px;
}
.udu-svg-editor-win.udu__popup-svg-mode {
  height: auto;
  width: 400px;
  opacity: 1;
  border: none !important;
  box-shadow: none !important;
  padding: 5px;
  box-sizing: border-box;
  z-index: 1;
}
.udu-svg-editor-win.udu__popup-svg-mode .udu__button-call-popup,
.udu-svg-editor-win.udu__popup-svg-mode .udu__svg-fld-comment {
  display: none !important;
}
.udu-svg-editor-win.udu__popup-svg-mode .svg-fields {
  width: 100%;
}
.udu-svg-editor-win .svg-fields {
  width: 20%;
  min-width: 225px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.udu-svg-editor-win .svg-fields input:not([type=checkbox]):not([type=radio]),
.udu-svg-editor-win .svg-fields textarea {
  border: 1px solid #eee !important;
  border-radius: 3px !important;
  box-shadow: none !important;
  font-family: "Arial", "Helvetica", "Verdana", "sans-serif" !important;
  font-size: 14px !important;
  padding: 5px 4px 4px 4px !important;
  width: 95% !important;
}
.udu-svg-editor-win .svg-fields input:not([type=checkbox]):not([type=radio]):not(.error):focus,
.udu-svg-editor-win .svg-fields textarea:not(.error):focus {
  background-color: #fcffd9 !important;
}
.udu-svg-editor-win .svg-fields select {
  border: 1px solid #eee;
  box-shadow: none !important;
  font-family: "Arial", "Helvetica", "Verdana", "sans-serif" !important;
  font-size: 14px !important;
  margin: 2px;
  width: 100%;
}
.udu-svg-editor-win .svg-fields select:not(.error):focus {
  background-color: #fcffd9 !important;
}
.udu-svg-editor-win .svg-fields select.fld-pre-select {
  min-width: 75px !important;
  max-width: 75px !important;
  margin: 0 4px 0 0 !important;
}
.udu-svg-editor-win .svg-fields input.error {
  background-image: url("../../img/btn/warning-1.png") !important;
  background-repeat: no-repeat !important;
  background-position: right 2px center !important;
  border: 1px solid #ffc1c3 !important;
}
.udu-svg-editor-win .svg-fields .fld-block {
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 4px;
  margin-bottom: 14px;
  order: 10000;
  padding: 3px;
}
.udu-svg-editor-win .svg-fields .fld-block.title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  order: 1;
}
.udu-svg-editor-win .svg-fields .fld-block .udu__svg-input-wrapper {
  display: flex;
}
.udu-svg-editor-win .svg-fields .fld-block .udu__svg-input-wrapper input,
.udu-svg-editor-win .svg-fields .fld-block .udu__svg-input-wrapper select:not(.fld-pre-select),
.udu-svg-editor-win .svg-fields .fld-block .udu__svg-input-wrapper textarea {
  margin: 0 !important;
}
.udu-svg-editor-win .svg-fields .fld-block .udu__svg-input-wrapper button {
  background: #eee;
  border: 1px solid #ddd;
  cursor: pointer;
  margin-left: 5px;
}
.udu-svg-editor-win .svg-fields .fld-block .udu__svg-input-wrapper button:hover {
  background: #e4e4e4;
}
.udu-svg-editor-win .svg-fields .fld-block.udu__hours-of-operation {
  padding-right: 0;
}
.udu-svg-editor-win .svg-fields .fld-block.udu__hours-of-operation .field {
  display: flex;
  flex-wrap: wrap;
}
.udu-svg-editor-win .svg-fields .fld-block.udu__hours-of-operation .field select,
.udu-svg-editor-win .svg-fields .fld-block.udu__hours-of-operation .field .udu__hoo-label {
  align-items: center;
  box-sizing: border-box !important;
  display: flex;
  font-size: 10px;
  margin: 1px;
  padding: 5px !important;
  width: calc(50% - 2px);
}
.udu-svg-editor-win .svg-fields .fld-block.udu__hours-of-operation .field select {
  font-size: 12px !important;
  padding: 5px !important;
}
.udu-svg-editor-win .svg-fields .fld-block .udu__svg-fld-comment {
  color: #999;
  font-size: 11px;
  padding: 5px;
}
.udu-svg-editor-win .svg-fields .fld-block div.max-length {
  font-size: 9px;
  padding: 4px;
}
.udu-svg-editor-win .svg-fields .fld-block div.max-length.red {
  color: red;
}
.udu-svg-editor-win .svg-fields .fld-block div.max-length .cnt-res {
  margin-left: 3px;
}
.udu-svg-editor-win .svg-fields .svg-fields td:last-child input {
  margin-bottom: 0;
}
.mode-F .svg-editor-popup .main-svg {
  border-left: 1px solid #eee;
  display: flex;
  justify-content: center;
  overflow: auto;
  width: 100%;
}
.mode-F .svg-editor-popup .main-svg svg {
  height: auto;
  width: auto;
}
.mode-F .buttons button:hover {
  background-position: -16px -15px;
}
.mode-F .buttons button.md-svg-zoom-in:hover {
  background-position: -17px -79px;
}
.mode-F .buttons button.md-svg-zoom-in {
  background-position: -79px -79px;
}
.mode-F .buttons:hover {
  opacity: 1;
}
.mode-F .buttons {
  opacity: 0.6;
  position: absolute;
  bottom: 20px;
  right: 30px;
  transition: all 0.6s ease-in-out 0s;
}
.mode-F .buttons button {
  background: transparent url("img/sprite.mode-d.png") -79px -14px no-repeat;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  box-shadow: 0 0 1px #000;
  width: 32px;
  height: 32px;
}
.mode-F svg {
  max-width: 3000px;
  max-height: 3000px;
}
.udu-saved-svg {
  display: none !important;
}

.svg-resizable-marker.svg-marker-1,
.svg-resizable-marker.svg-marker-5,
.svg-resizable-block-div.svg-marker-1,
.svg-resizable-block-div.svg-marker-5 {
  background-position: -35px -2px;
  cursor: n-resize;
}
.svg-resizable-marker.svg-marker-3,
.svg-resizable-marker.svg-marker-7,
.svg-resizable-block-div.svg-marker-3,
.svg-resizable-block-div.svg-marker-7 {
  background-position: -19px -2px;
  cursor: e-resize;
}
.svg-resizable-marker.svg-marker-0,
.svg-resizable-marker.svg-marker-4,
.svg-resizable-block-div.svg-marker-0,
.svg-resizable-block-div.svg-marker-4 {
  cursor: se-resize;
}
.svg-resizable-marker.svg-marker-2,
.svg-resizable-marker.svg-marker-6,
.svg-resizable-block-div.svg-marker-2,
.svg-resizable-block-div.svg-marker-6 {
  background-position: -50px -2px;
  cursor: ne-resize;
}
.svg-resizable-marker {
  background: rgba(248, 248, 150, 0.6) url("img/sprite.resizable.png") -2px -2px no-repeat;
  border: 1px solid rgba(100, 100, 100, 0.6);
  border-radius: 6px;
  box-shadow: 0 0 1px #fff;
  height: 11px;
  left: -200px;
  position: absolute;
  width: 11px;
  z-index: 50000;
}
.svg-resizable-block-div {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 51000;
}
.udu__ctr-is-down .svg-resizable-marker.svg-resizable-marker.svg-marker-0,
.udu__ctr-is-down .svg-resizable-marker.svg-resizable-marker.svg-marker-2,
.udu__ctr-is-down .svg-resizable-marker.svg-resizable-marker.svg-marker-4,
.udu__ctr-is-down .svg-resizable-marker.svg-resizable-marker.svg-marker-6 {
  background-color: #a5c9d2 !important;
}

.svg-rotatable-marker div {
}
.svg-rotatable-marker, .svg-rotatable-cover-div .marker-4 {
	background: rgba(0, 191, 243, 0.6) url("img/sprite.rotatable.png") 4px 4px no-repeat;
	border: 1px solid rgba(100, 100, 100, 0.6);
	border-radius: 14px;
	box-shadow: 0 0 2px #fff;
	cursor: pointer;
	display: none;
	height: 24px;
	position: absolute;
	width: 24px;
}
.svg-rotatable-block-div {
	bottom: 0;
	cursor: pointer;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 51000;
}
.svg-rotatable-cover-div .marker-4 {
	display: block;
	right: -24px;
}
.svg-rotatable-cover-div .marker-3 {
	background-position: -5px -28px;
	bottom: 0;
	left: 0;
}
.svg-rotatable-cover-div .marker-2 {
	background-position: -27px -28px;
	bottom: 0;
	right: 0;
}
.svg-rotatable-cover-div .marker-1 {
	background-position: -27px -4px;
	right: 0;
	top: 0;
}
.svg-rotatable-cover-div div {
	background: url("img/sprite.rotatable.png") -51px -4px no-repeat;
	height: 12px;
	position: absolute;
	width: 12px;
}
.svg-rotatable-cover-div {
	background: url("img/center.rotatable.png") center center no-repeat;
	display: none;
	position: absolute;
}
.svg-text-editable-wrapper input, .svg-text-editable-wrapper textarea {
	background-color: transparent;
	border: none;
	font-family: arial, tahoma, verdana, helvetica, sans-serif;
	font-size: 14px;
	height: 100%;
	width: 100%;
}
.svg-text-editable-wrapper textarea {
	font-size: 11px;
	resize: none;
}
.svg-text-editable-wrapper {
	background-color: rgba(255, 252, 160, 0.6);
	border: 1px solid #eee;
	display: none;
	position: absolute;
	z-index: 10000;
}
.svg-text-editable-wrapper .corner-0 {
	border-left: 0;
	border-top: 0;
	left: -10px;
	top: -10px;
}
.svg-text-editable-wrapper .corner-1 {
	border-right: 0;
	border-top: 0;
	right: -10px;
	top: -10px;
}
.svg-text-editable-wrapper .corner-2 {
	border-bottom: 0;
	border-right: 0;
	bottom: -10px;
	right: -10px;
}
.svg-text-editable-wrapper .corner-3 {
	border-bottom: 0;
	border-left: 0;
	bottom: -10px;
	left: -10px;
}
.svg-text-editable-wrapper div {
	border: 1px dotted #000;
	height: 10px;
	position: absolute;
	width: 10px;
}
.svg-embeddable-block-div {
	background-color: rgba(0, 0, 0, 0.6);
	bottom: 0;
	cursor: default;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 51000;
}
.svg-embeddable-upload-div {
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 0 5px #000;
	display: none;
	height: 400px;
	left: 50%;
	margin-left: -250px;
	margin-top: -200px;
	overflow: hidden;
	position: fixed;
	top: 50%;
	width: 500px;
	z-index: 60000;
}
.svg-embeddable-upload-div .close:hover {
	background-position: 0 -37px;
}
.svg-embeddable-upload-div .preview img {
	max-height: 250px;
	max-width: 250px;
}
.svg-embeddable-upload-div .preview, .svg-embeddable-upload-div .logo-list {
	border: 1px solid #ccc;
	bottom: 90px;
	left: 50px;
	position: absolute;
	right: 50px;
	text-align: center;
	top: 50px;
	vertical-align: middle;
}
.svg-embeddable-upload-div .preview {
	overflow: hidden;
}
.svg-embeddable-upload-div .ul-logo-list li img {
	max-height: 64px;
	max-width: 64px;
}
.svg-embeddable-upload-div .ul-logo-list li:hover > span {
	color: #fff;
	border:1px solid #000;
	background-color: rgba(0, 0, 0, 0.8);
	box-shadow: 0 0 3px #ccc;
}
.svg-embeddable-upload-div .ul-logo-list li span.logo-num {
	bottom: inherit;
	left: inherit;
	min-width: 20px;
	top: 2px;
	overflow: auto;
}
.svg-embeddable-upload-div .ul-logo-list li > span {
	position: absolute;
	bottom: 2px;
	left: 2px;
	right: 2px;
	font-size: 10px;
	color: #222;
	background-color: rgba(255, 255, 255, 0.6);
	font-family: verdana, arial, helvetica;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	transition: all 0.4s ease-in-out 0s;
}
.svg-embeddable-upload-div .ul-logo-list li:hover {
	border:1px solid #000;
	box-shadow: 0 0 3px #ccc;
}
.svg-embeddable-upload-div button.logo-select {
	margin-right: 5px;
}
.svg-embeddable-upload-div .ul-logo-list li {
	display: inline-block;
	width: 68px;
	height: 68px;
	border: 1px solid #ccc;
	padding: 2px;
	margin: 0 2px 2px 0;
	border-radius: 3px;
	float: left;
	text-align: center;
	position: relative;
	cursor: pointer;
	transition: all 0.4s ease-in-out 0s;
}
.svg-embeddable-upload-div .ul-logo-list {
	margin: 0;
	padding: 2px;
	list-style: none;
	width: 455px;
}
.svg-embeddable-upload-div .hidden {
	display: none !important;
}
.svg-embeddable-upload-div .loading {
	background-image: url("img/preloader.gif");
	background-position: center center;
	background-repeat: no-repeat;
}
.svg-embeddable-upload-div .logo-list {
	z-index: 100;
	background-color: #fff !important;
	overflow-x: hidden;
	overflow-y: scroll;
}
.svg-embeddable-upload-div .close {
	background: url("img/sprite.embeddable.image.png") 0 0 no-repeat;
	border: none;
	cursor: pointer;
	height: 36px;
	position: absolute;
	right: 5px;
	top: 5px;
	width: 36px;
}
.svg-embeddable-upload-div .apply {
	float: right;
}
.svg-embeddable-upload-div .upload input {
	width: 220px;
}
.svg-embeddable-upload-div .search-box input:focus {
	background-color: #ffffcd;
	width: 120px;
}
.svg-embeddable-upload-div .search-box input {
	width: 70px;
	border: 1px solid #ddd;
	background-color: #fff;
	transition: all 0.4s ease-in-out 0s;
}
.svg-embeddable-upload-div .ul-logo-list li span.logo-name span {
	background-color: #fef084 !important;
	color: #000 !important;
}
.svg-embeddable-upload-div .search-box.focused {
	opacity: 1;
}
.svg-embeddable-upload-div .search-box {
	font-family: arial, tahoma, verdana, helvetica, sans-serif;
	position: absolute;
	right: -1px;
	top: -1px;
	border: 1px solid #eee;
	padding: 4px;
	z-index: 200;
	background-color: #fff;
	opacity: 0.9;
	transition: all 0.4s ease-in-out 0s;
}
.svg-embeddable-upload-div .upload {
	background: #eee;
	bottom: 0;
	left: 0;
	padding: 20px;
	position: absolute;
	right: 0;
}
/*
colpick Color Picker / colpick.com
*/

/*Main container*/
.colpick {
	position: absolute;
	width: 346px;
	height: 180px;
	overflow: hidden;
	display: none;
	font-family: Arial, Helvetica, sans-serif;
	background:#ebebeb;
	border: 1px solid #bbb;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	
	/*Prevents selecting text when dragging the selectors*/
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
/*Color selection box with gradients*/
.colpick_color {
	position: absolute;
	left: 7px;
	top: 7px;
	width: 156px;
	height: 156px;
	overflow: hidden;
	outline: 1px solid #aaa;
	cursor: crosshair;
}
.colpick_color_overlay1 {
	position: absolute;
	left:0;
	top:0;
	width: 156px;
	height: 156px;
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr='#ffffff', endColorstr='#00ffffff')"; /* IE8 */
	background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
	filter:  progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr='#ffffff', endColorstr='#00ffffff'); /* IE6 & IE7 */
}
.colpick_color_overlay2 {
	position: absolute;
	left:0;
	top:0;
	width: 156px;
	height: 156px;
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#00000000', endColorstr='#000000')"; /* IE8 */
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}
/*Circular color selector*/
.colpick_selector_outer {
	background:none;
	position: absolute;
	width: 11px;
	height: 11px;
	margin: -6px 0 0 -6px;
	border: 1px solid black;
	border-radius: 50%;
}
.colpick_selector_inner{
	position: absolute;
	width: 9px;
	height: 9px;
	border: 1px solid white;
	border-radius: 50%;
}
/*Vertical hue bar*/
.colpick_hue {
	position: absolute;
	top: 6px;
	left: 175px;
	width: 19px;
	height: 156px;
	border: 1px solid #aaa;
	cursor: n-resize;
}
/*Hue bar sliding indicator*/
.colpick_hue_arrs {
	position: absolute;
	left: -8px;
	width: 35px;
	height: 7px;
	margin: -7px 0 0 0;
}
.colpick_hue_larr {
	position:absolute;
	width: 0; 
	height: 0; 
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 7px solid #858585;
}
.colpick_hue_rarr {
	position:absolute;
	right:0;
	width: 0; 
	height: 0; 
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent; 
	border-right: 7px solid #858585; 
}
/*New color box*/
.colpick_new_color {
	position: absolute;
	left: 207px;
	top: 6px;
	width: 60px;
	height: 27px;
	background: #f00;
	border: 1px solid #8f8f8f;
}
/*Current color box*/
.colpick_current_color {
	position: absolute;
	left: 277px;
	top: 6px;
	width: 60px;
	height: 27px;
	background: #f00;
	border: 1px solid #8f8f8f;
}
/*Input field containers*/
.colpick_field, .colpick_hex_field  {
	position: absolute;
	height: 20px;
	width: 60px;
	overflow:hidden;
	background:#f3f3f3;
	color:#b8b8b8;
	font-size:12px;
	border:1px solid #bdbdbd;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.colpick_rgb_r {
	top: 40px;
	left: 207px;
}
.colpick_rgb_g {
	top: 67px;
	left: 207px;
}
.colpick_rgb_b {
	top: 94px;
	left: 207px;
}
.colpick_hsb_h {
	top: 40px;
	left: 277px;
}
.colpick_hsb_s {
	top: 67px;
	left: 277px;
}
.colpick_hsb_b {
	top: 94px;
	left: 277px;
}
.colpick_hex_field {
	width: 68px;
	left: 207px;
	top: 121px;
}
/*Text field container on focus*/
.colpick_focus {
	border-color: #999;
}
/*Field label container*/
.colpick_field_letter {
	position: absolute;
	width: 12px;
	height: 20px;
	line-height: 20px;
	padding-left: 4px;
	background: #efefef;
	border-right: 1px solid #bdbdbd;
	font-weight: bold;
	color:#777;
}
/*Text inputs*/
.colpick_field input, .colpick_hex_field input {
	position: absolute;
	right: 11px;
	margin: 0;
	padding: 0;
	height: 20px;
	line-height: 20px;
	background: transparent;
	border: none;
	font-size: 12px;
	font-family: Arial, Helvetica, sans-serif;
	color: #555;
	text-align: right;
	outline: none;
}
.colpick_hex_field input {
	right: 4px;
}
/*Field up/down arrows*/
.colpick_field_arrs {
	position: absolute;
	top: 0;
	right: 0;
	width: 9px;
	height: 21px;
	cursor: n-resize;
}
.colpick_field_uarr {
	position: absolute;
	top: 5px;
	width: 0; 
	height: 0; 
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 4px solid #959595;
}
.colpick_field_darr {
	position: absolute;
	bottom:5px;
	width: 0; 
	height: 0; 
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #959595;
}
/*Submit/Select button*/
.colpick_submit {
	position: absolute;
	left: 207px;
	top: 149px;
	width: 130px;
	height: 22px;
	line-height:22px;
	background: #efefef;
	text-align: center;
	color: #555;
	font-size: 12px;
	font-weight:bold;
	border: 1px solid #bdbdbd;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.colpick_submit:hover {
	background:#f3f3f3;
	border-color:#999;
	cursor: pointer;
}

/*full layout with no submit button*/
.colpick_full_ns  .colpick_submit, .colpick_full_ns .colpick_current_color{
	display:none;
}
.colpick_full_ns .colpick_new_color {
	width: 130px;
	height: 25px;
}
.colpick_full_ns .colpick_rgb_r, .colpick_full_ns .colpick_hsb_h {
	top: 42px;
}
.colpick_full_ns .colpick_rgb_g, .colpick_full_ns .colpick_hsb_s {
	top: 73px;
}
.colpick_full_ns .colpick_rgb_b, .colpick_full_ns .colpick_hsb_b {
	top: 104px;
}
.colpick_full_ns .colpick_hex_field {
	top: 135px;
}

/*rgbhex layout*/
.colpick_rgbhex .colpick_hsb_h, .colpick_rgbhex .colpick_hsb_s, .colpick_rgbhex .colpick_hsb_b {
	display:none;
}
.colpick_rgbhex {
	width:282px;
}
.colpick_rgbhex .colpick_field, .colpick_rgbhex .colpick_submit {
	width:68px;
}
.colpick_rgbhex .colpick_new_color {
	width:34px;
	border-right:none;
}
.colpick_rgbhex .colpick_current_color {
	width:34px;
	left:240px;
	border-left:none;
}

/*rgbhex layout, no submit button*/
.colpick_rgbhex_ns  .colpick_submit, .colpick_rgbhex_ns .colpick_current_color{
	display:none;
}
.colpick_rgbhex_ns .colpick_new_color{
	width:68px;
	border: 1px solid #8f8f8f;
}
.colpick_rgbhex_ns .colpick_rgb_r {
	top: 42px;
}
.colpick_rgbhex_ns .colpick_rgb_g {
	top: 73px;
}
.colpick_rgbhex_ns .colpick_rgb_b {
	top: 104px;
}
.colpick_rgbhex_ns .colpick_hex_field {
	top: 135px;
}

/*hex layout*/
.colpick_hex .colpick_hsb_h, .colpick_hex .colpick_hsb_s, .colpick_hex .colpick_hsb_b, .colpick_hex .colpick_rgb_r, .colpick_hex .colpick_rgb_g, .colpick_hex .colpick_rgb_b {
	display:none;
}
.colpick_hex {
	width:206px;
	height:201px;
}
.colpick_hex .colpick_hex_field {
	width:72px;
	height:25px;
	top:168px;
	left:80px;
}
.colpick_hex .colpick_hex_field div, .colpick_hex .colpick_hex_field input {
	height: 25px;
	line-height: 25px;
}
.colpick_hex .colpick_new_color {
	left:9px;
	top:168px;
	width:30px;
	border-right:none;
}
.colpick_hex .colpick_current_color {
	left:39px;
	top:168px;
	width:30px;
	border-left:none;
}
.colpick_hex .colpick_submit {
	left:164px;
	top: 168px;
	width:30px;
	height:25px;
	line-height: 25px;
}

/*hex layout, no submit button*/
.colpick_hex_ns  .colpick_submit, .colpick_hex_ns .colpick_current_color {
	display:none;
}
.colpick_hex_ns .colpick_hex_field {
	width:80px;
}
.colpick_hex_ns .colpick_new_color{
	width:60px;
	border: 1px solid #8f8f8f;
}

/*Dark color scheme*/
.colpick_dark {
	background: #161616;
	border-color: #2a2a2a;
}
.colpick_dark .colpick_color {
	outline-color: #333;
}
.colpick_dark .colpick_hue {
	border-color: #555;
}
.colpick_dark .colpick_field, .colpick_dark .colpick_hex_field {
	background: #101010;
	border-color: #2d2d2d;
}
.colpick_dark .colpick_field_letter {
	background: #131313;
	border-color: #2d2d2d;
	color: #696969;
}
.colpick_dark .colpick_field input, .colpick_dark .colpick_hex_field input {
	color: #7a7a7a;
}
.colpick_dark .colpick_field_uarr {
	border-bottom-color:#696969;
}
.colpick_dark .colpick_field_darr {
	border-top-color:#696969;
}
.colpick_dark .colpick_focus {
	border-color:#444;
}
.colpick_dark .colpick_submit {
	background: #131313;
	border-color:#2d2d2d;
	color:#7a7a7a;
}
.colpick_dark .colpick_submit:hover {
	background-color:#101010;
	border-color:#444;
}